
/*********** fonts *************/
/* #BG */
#bg {
  background-color: #151515;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
}

#bg-svg {
  position: fixed;
  left: -25%;
  top: -25%;
  z-index: -1;
  overflow: hidden;
  pointer-events: none;
  filter: blur(150px);
}


#bg-noise {
  /*  */
  background-image: url('./assets/images/noise.png');
  background-repeat: round;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: calc(100% + 30rem);
  height: calc(100% + 20rem);
  mix-blend-mode: overlay;
  opacity: 25%;
  pointer-events: none;
}



#bg-gradient {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -2;
  top: 0;
  left: 0;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(21, 21, 21, 0.9) 0%, rgba(21, 21, 21, 0) 100%);
}
  
/* SVG Elements Animation */
#Pentagon {
  animation: movePentagon 10s infinite linear;
}

@keyframes movePentagon {
  0% {
    transform: translate(0%, 0%) rotate(0deg);
  }

  25% {
    transform: translate(10%, 60%) rotatez(45deg)
  }

  50% {
    transform: translate(70%, 80%)rotatez(90deg)
  }

  75% {
    transform: translate(80%, 0%)rotatez(45deg)
  }
}

#Triangle {
  animation: moveTriangle 14s infinite linear;

}

@keyframes moveTriangle {
  0% {
    transform: translate(0%, 0%) rotate(0deg);
  }

  50% {
    transform: translate(10%, 10%) rotatez(-45deg)
  }
}

#Square {
  animation: moveSquare 18s infinite linear;
}

@keyframes moveSquare {
  0% {
    transform: translate(0%, 0%)
  }

  25% {
    transform: translate(60%, 10%)
  }

  50% {
    transform: translate(60%, -80%)
  }

  75% {
    transform: translate(00%, -80%)
  }
}

#Circle {
  animation: moveCircle 16s infinite linear;
}

@keyframes moveCircle {
  0% {
    transform: translate(0%, 0%)
  }

  25% {
    transform: translate(-50%, 0%)
  }

  50% {
    transform: translate(-50%, 50%)
  }

  75% {
    transform: translate(0%, 50%)
  }
}

#Star {
  animation: moveStar 20s infinite linear;
}

@keyframes moveStar {
  0% {
    transform: translate(0%, 0%)
  }

  25% {
    transform: translate(-10%, -50%) rotatez(-18deg)
  }

  50% {
    transform: translate(-70%, -20%) rotatez(-36deg)
  }

  75% {
    transform: translate(-70%, -0%) rotatez(-18deg)
  }
}

/* #endregion BG */

